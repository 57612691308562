.ant-table-wrapper {
  margin-top: 10px;
}

.Totals-row > td {
  background: #fafafa;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

.user-column a {
  color: rgba(0, 0, 0, 0.65);
}

tr:not(.inactive-user-row) > td.user-column {
  width: 250px;
  background: #fafafa;
}

.darkMode td.user-column {
  background: transparent !important;
}

.darkMode .user-column a {
  color: rgba(255, 255, 255, 0.85) !important;
}

.inactive-user-row {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
}

.inactive-user-row a {
  color: rgba(0, 0, 0, 0.25);
}

.controls-right {
  text-align: right;
}

.controls-right button {
  width: 100%;
}
