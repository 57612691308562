.times-last-project-name {
  margin-right: 5px;
  font-size: 0.9em;
}

.times-last-times {
  line-height: 1em;
  margin-top: -20px;
  margin-bottom: 15px;
}
.times-last-times a:not(:last-child)::after {
  content: '|';
  margin-left: 0.5em;
  display: inline-block;
  color: rgba(0, 0, 0, 0.65);
}
