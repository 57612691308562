.w-md-editor {
  --md-editor-background-color: transparent;
}

.w-md-editor-fullscreen {
  --md-editor-background-color: var(--color-canvas-default, #ffffff) !important;
}

.wmde-markdown {
  background-color: transparent !important;
}
