.ant-table {
  white-space: nowrap;
}

.user-link a {
  color: rgba(0, 0, 0, 0.65);
}

.raport-day {
  text-align: right;
  position: relative;
}
.raport-day a {
  color: rgba(0, 0, 0, 0.65);
}
.darkMode .raport-day a {
  color: rgba(255, 255, 255, 0.85);
}
.raport-day a:hover {
  text-decoration: underline;
}

.non-working {
  background-color: #f1f1f1 !important;
}

.darkMode .non-working {
  background-color: #3c3c3c !important;
}

.working {
  background-color: #bae7ff !important;
}

.darkMode .working {
  background-color: #7193a4 !important;
}

td.working {
  background-color: #e6f7ff;
}
.remote-work {
  background-color: #b0d0b0;
}

.darkMode .remote-work,
.darkMode .non-working.remote-work {
  background-color: #5c805c !important;
}

.day-off {
  background-color: #c0bfbf;
}

.non-working.remote-work {
  background-color: #b0d0b0 !important;
}
.non-working.day-off {
  background-color: #c0bfbf !important;
}
td.working.remote-work {
  background-color: #b0d0b0 !important;
}
.darkMode .working.remote-work {
  background-color: #5c805c !important;
}
td.working.day-off {
  background-color: #b5c7cf !important;
}

.non-working.day-off {
  background-color: #c0bfbf90 !important;
}
.darkMode td.working.day-off {
  background-color: #394345 !important;
}
.zero-time a {
  color: grey;
}
.darkMode .zero-time a {
  color: rgba(255, 255, 255, 0.65);
}
tr:hover > td {
  background-color: #e6f7ff !important;
}
.darkMode tr:hover > td {
  background-color: #2a2a2afc !important;
}
tr:hover > td.remote-work {
  background-color: #b0d0b0 !important;
}
tr:hover > td.day-off {
  background-color: #c0cfd6 !important;
}

.darkMode tr:hover > td.remote-work {
  background-color: #5c805c !important;
}
.darkMode tr:hover > td.day-off {
  background-color: #394345 !important;
}
th.vertical-text {
  text-align: center !important;
  writing-mode: vertical-lr;
  text-orientation: mixed;
}
.good-employee {
  color: #228b22;
}
.darkMode .chaotic-employee a,
.chaotic-employee,
.chaotic-employee a {
  color: #a90000;
}
.bad-employee {
  color: #ff000f;
}
.full-block {
  display: block;
}
.part-time-employee {
  color: rgba(0, 0, 0, 0.3);
}

.monthly-inscribed-wrong-day {
  color: #ff000f;
  font-size: 0.5rem;
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.businessTrip {
  color: rgb(255, 174, 0);
  font-size: 0.5rem;
  position: absolute;
  bottom: 4px;
  left: 4px;
}

.monthly-view th.user-link {
  height: 90px;
}

.no-content .ant-table-placeholder {
  display: none;
}

.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}

.no-space.ant-table-wrapper {
  margin: 0 !important;
}
.no-space .ant-table-small.ant-table-bordered {
  border-top: 0;
}
.opacity-0 {
  opacity: 0;
}

.viewContainer {
  display: flex;
  gap: 10px;
}

.darkMode .day-off,
.darkMode .non-working.day-off {
  background-color: #394345 !important;
}

@media screen and (max-width: 425px) {
  .monthly-view .ant-table-fixed-right {
    transition: transform 0.2s;
  }
  .monthly-view--hide-total .ant-table-fixed-right {
    background: white;
    transform: translateX(200px);
  }
  .monthly-view--hide-total .ant-table-content .total-col {
    display: none;
  }

  .monthly-view--hide-total
    .ant-table-fixed-columns-in-body.raport-day.vertical-text {
    display: none;
  }
  .viewContainer {
    gap: 5px;
    margin-left: 5px;
  }
}

.ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td.remote-work {
  background: #b0d0b0;
}

.ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td.day-off {
  background: #c0bfbf;
}

.ant-badge {
  z-index: 0;
}
