.ant-col-md-6 {
  padding-bottom: 6px;
}

.public-links-modal {
  width: 1080px !important;
}

.search-operations {
  margin-bottom: 6px;
}

.echarts-for-react {
  height: auto;
}
