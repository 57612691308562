.text-center {
  text-align: center;
}

.full-height {
  height: 100%;
}

.user-form {
  max-width: 300px;
  text-align: left;
  margin: auto;
  margin-top: 20px;
}
.user-form-input {
  margin-bottom: 8px;
}

.user-form-save {
  text-align: center;
}

.ant-picker-cell {
  padding: 1px !important;
}
