.text-center--force {
  text-align: center !important;
}

.color-primary {
  color: #1890ff;
}

.color-grey {
  color: grey;
}

.table-operations {
  text-align: right;
  margin-bottom: 16px !important;
}

.filter-operations {
  margin-bottom: 16px !important;
}

.page-filters {
  text-align: left;
}
.page-buttons {
  text-align: right;
}

img {
  background: transparent;
}

.float-right {
  float: right;
}

.align-right {
  align-items: right;
}

.card-container {
  width: 100%;
  text-align: center;
}

.tab-table {
  overflow-x: scroll;
  width: 100%;
}

.card-container > .ant-tabs-card > .ant-tabs-tab-active {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
  width: 100%;
}

.card-container > .ant-tabs-card > .ant-tabs-content {
  margin-top: -16px;
}

.group-select {
  width: 100%;
}

.logo {
  height: 44px;
  vertical-align: top;
  margin-bottom: 24px;
}

.record--deleted {
  text-decoration: line-through !important;
}

.wrapable {
  white-space: normal;
}
.darkMode .ant-modal-content {
  background-color: #1d1d1d;
}

.darkMode .ant-modal-confirm-title,
.darkMode .ant-modal-confirm-content,
.darkMode .ant-modal-confirm-btns .ant-btn-default {
  color: rgba(255, 255, 255, 0.85);
}

.darkMode .ant-modal-content button {
  background-color: #1d1d1d;
}

.darkMode .ant-modal-content .ant-switch {
  background-color: #434343;
}
.darkMode .ant-modal-content .ant-switch:hover {
  background-color: #5c5c5c;
}

.darkMode .ant-modal-content .ant-btn-primary {
  background-color: #1677ff;
}
.ant-layout-content {
  margin-bottom: 24px !important;
}

.action-column--visible .action-buttons {
  visibility: visible !important;
}

.action-overlay-area {
  position: relative;
}
.action-overlay {
  z-index: 1000;
  position: absolute;
  right: 20px;
  transform: translateY(-50%);
  top: 50%;
  display: none;
}
.action-overlay-area:hover .action-overlay {
  display: block;
}
.action-overlayed {
  z-index: 1;
  position: relative;
  transition: display 0.3s, opacity 0.2s;
  height: 100%;
  opacity: 1;
}
.action-overlay-area:hover .action-overlayed {
  opacity: 0.2;
}
.question-popover {
  position: relative;
  font-size: 1.7em;
  top: 4px;
  left: 5px;
}

.ant-menu-dark .ant-menu-item > a.active {
  color: rgba(255, 255, 255, 1);
}

.menu-badge {
  margin-right: 0 !important;
  top: -2px;
}
.menu-badge sup {
  padding-left: 0px;
  padding-right: 0px;
  box-shadow: none;
  font-size: 9px;
}
.ant-menu-inline-collapsed .menu-badge sup {
  margin-top: 10px;
}
.ant-menu-inline .menu-badge sup {
  right: 10px;
  font-size: 8px;
  min-width: 17px;
  height: 17px;
  line-height: 17px;
}

.remarks-table-column {
  max-width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  /* handle range date picker on mobile */
  .ant-calendar-range {
    width: 276px;
  }
  .ant-calendar-range .ant-calendar-range-middle {
    display: none;
  }
  .ant-calendar-range .ant-calendar-input {
    width: 276px;
  }
  .ant-calendar-range .ant-calendar-date-input-wrap {
    margin: 0;
  }
  .ant-calendar-range .ant-calendar-range-part {
    width: 100%;
  }
  .ant-calendar-range .ant-calendar-range-right {
    float: left;
    border-top: 1px solid #e8e8e8;
  }
}
@media screen and (max-width: 425px) {
  .ant-card-head {
    padding-left: 12px;
    padding-right: 12px;
  }
  .ant-card-body {
    padding: 12px;
  }
  .wall-post-body {
    margin-top: 0.7rem;
  }
}
