tr:hover > td > .action-buttons {
  visibility: visible !important;
}

tr > td > .action-buttons {
  visibility: hidden !important;
}

.action-buttons > * {
  margin-left: 4px;
}

.action-buttons > .ant-btn {
  transition: 0ms !important;
  -webkit-transition: 0ms !important;
}

th.color-bar {
  padding: 0 !important;
  width: 8px !important;
}

tbody > tr > td.color-bar {
  padding: 0 !important;
  width: 8px !important;
}

tr.selected:hover > td {
  background-color: #f0f5ff;
}

tr.selected > td {
  background-color: #f0f5ff;
}

tr:hover > td {
  background-color: #fafafa !important;
}

tr.selectable:hover > td {
  cursor: pointer;
}
.darkMode .twitter-picker {
  background-color: #353535 !important;
}

.darkMode .selected > td {
  background-color: #353535;
}
